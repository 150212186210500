import React from "react"
import styled from "styled-components"
import INFO from "../images/PSACore_Diagram.svg"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 60px auto 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;

    a {
        width: 255px;
        display: block;
        float:left;

        + p {
            width: 100%;
            float:left;
        }
    }

    .info {
        width: 50%;
        margin: auto;
    }

    @media only screen and (max-width: 1140px) {
        margin-top: 7px;
        margin-bottom: 20px;

        a {
            width: 100%;
            display: block;
        }

        .info {
            width: 80%;
            margin: auto;
        }
    }
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-bottom: 100px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 90px;
    }
`;

const Content = styled.div `
    width: 60%;
    padding-top: 60px;
    margin-bottom: 80px;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0;
        margin-top: 48px;
        margin-bottom: 30px;
    }

    &.benefits {
        width: 40%;
        padding-left: 60px;

        @media only screen and (max-width: 1140px) {
            width: 100%;
            padding-left: 0;
        }
    }
`;

const Benefits = styled.div `
    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0;
        margin-top: 0;
    }
`;


const logo = () => (
    <svg width="385" height="65" viewBox="0 0 385 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_27_16470)">
        <path d="M80.9883 16.661H88.5736V43.249H104.993V49.4297H80.9883V16.661Z" fill="#2D93D0"/>
        <path d="M133.627 42.4112H118.425L115.517 49.4297H107.742L122.344 16.661H129.834L144.483 49.4297H136.535L133.627 42.4112ZM131.241 36.6573L126.042 24.1063L120.843 36.6573H131.225H131.241Z" fill="#2D93D0"/>
        <path d="M146.965 16.661H161.851C165.407 16.661 168.551 17.3407 171.285 18.7001C174.019 20.0596 176.137 21.9723 177.654 24.4382C179.171 26.9042 179.929 29.7811 179.929 33.0533C179.929 36.3254 179.171 39.2023 177.654 41.6683C176.137 44.1342 174.019 46.0469 171.285 47.4064C168.551 48.7658 165.407 49.4455 161.851 49.4455H146.965V16.661ZM161.472 43.2016C164.743 43.2016 167.366 42.2848 169.31 40.4669C171.254 38.6491 172.233 36.1673 172.233 33.0533C172.233 29.9392 171.254 27.4574 169.31 25.6396C167.366 23.8217 164.743 22.9049 161.472 22.9049H154.55V43.2174H161.472V43.2016Z" fill="#2D93D0"/>
        <path d="M182.805 16.661H197.691C201.246 16.661 204.391 17.3407 207.125 18.7001C209.859 20.0596 211.976 21.9723 213.493 24.4382C215.01 26.9042 215.769 29.7811 215.769 33.0533C215.769 36.3254 215.01 39.2023 213.493 41.6683C211.976 44.1342 209.859 46.0469 207.125 47.4064C204.391 48.7658 201.246 49.4455 197.691 49.4455H182.805V16.661ZM197.312 43.2016C200.583 43.2016 203.206 42.2848 205.15 40.4669C207.093 38.6491 208.073 36.1673 208.073 33.0533C208.073 29.9392 207.093 27.4574 205.15 25.6396C203.206 23.8217 200.583 22.9049 197.312 22.9049H190.39V43.2174H197.312V43.2016Z" fill="#2D93D0"/>
        <path d="M241.323 18.1153C243.456 19.0795 245.099 20.4548 246.253 22.241C247.407 24.0114 247.991 26.1296 247.991 28.564C247.991 30.9983 247.407 33.0691 246.253 34.8553C245.099 36.6415 243.456 38.0168 241.323 38.981C239.189 39.9295 236.661 40.4037 233.769 40.4037H227.163V49.4297H219.578V16.661H233.753C236.661 16.661 239.173 17.151 241.307 18.1153H241.323ZM238.541 32.7371C239.727 31.7571 240.311 30.366 240.311 28.5482C240.311 26.7303 239.711 25.2918 238.541 24.3118C237.356 23.3317 235.618 22.8417 233.342 22.8417H227.163V34.223H233.342C235.618 34.223 237.356 33.733 238.541 32.7529V32.7371Z" fill="#004C78"/>
        <path d="M256.161 48.9397C253.743 48.2442 251.8 47.3273 250.33 46.205L252.906 40.4985C254.312 41.526 255.972 42.348 257.915 42.9803C259.843 43.5968 261.787 43.9129 263.715 43.9129C265.864 43.9129 267.46 43.5968 268.487 42.9487C269.514 42.3164 270.036 41.4628 270.036 40.4037C270.036 39.6291 269.736 38.981 269.119 38.4594C268.519 37.9377 267.729 37.5268 266.781 37.2264C265.832 36.9103 264.537 36.5625 262.925 36.1989C260.428 35.5983 258.389 35.0134 256.793 34.4285C255.197 33.8436 253.838 32.8794 252.7 31.5674C251.563 30.2554 250.994 28.5165 250.994 26.3193C250.994 24.4224 251.515 22.6994 252.542 21.1503C253.57 19.6012 255.118 18.384 257.204 17.483C259.274 16.582 261.819 16.1235 264.805 16.1235C266.891 16.1235 268.946 16.3765 270.937 16.8665C272.928 17.3723 274.682 18.0837 276.183 19.0163L273.844 24.7702C270.81 23.063 267.792 22.1936 264.774 22.1936C262.656 22.1936 261.092 22.5413 260.065 23.2211C259.053 23.9008 258.547 24.8176 258.547 25.9399C258.547 27.0623 259.132 27.9001 260.302 28.4375C261.471 28.9908 263.257 29.5282 265.659 30.0499C268.155 30.6505 270.194 31.2354 271.79 31.8203C273.386 32.4052 274.745 33.3536 275.883 34.634C277.021 35.9144 277.59 37.6532 277.59 39.8346C277.59 41.6999 277.068 43.4229 276.025 44.9562C274.982 46.5054 273.418 47.7225 271.316 48.6394C269.23 49.5404 266.686 49.9988 263.683 49.9988C261.092 49.9988 258.595 49.651 256.177 48.9397H256.161Z" fill="#004C78"/>
        <path d="M303.395 42.4112H288.193L285.286 49.4297H277.511L292.112 16.661H299.603L314.252 49.4297H306.303L303.395 42.4112ZM301.009 36.6573L295.81 24.1063L290.611 36.6573H300.993H301.009Z" fill="#004C78"/>
        <path d="M316.686 16.661H319.072V47.2799H337.924V49.4297H316.686V16.661Z" fill="#B3B3B3"/>
        <path d="M340.784 18.5105C340.404 18.1469 340.215 17.7043 340.215 17.1826C340.215 16.661 340.404 16.2026 340.784 15.8232C341.163 15.4438 341.605 15.2541 342.143 15.2541C342.68 15.2541 343.123 15.428 343.502 15.7916C343.881 16.1552 344.071 16.5978 344.071 17.1194C344.071 17.6411 343.881 18.0995 343.502 18.4788C343.123 18.8582 342.68 19.0479 342.143 19.0479C341.605 19.0479 341.163 18.874 340.784 18.5105ZM340.973 24.9915H343.312V49.4297H340.973V24.9915Z" fill="#B3B3B3"/>
        <path d="M362.023 47.8806C361.423 48.4497 360.696 48.8765 359.795 49.161C358.91 49.4613 357.978 49.6036 357.014 49.6036C354.896 49.6036 353.253 49.0187 352.099 47.849C350.946 46.6792 350.361 45.0511 350.361 42.9645V26.999H345.873V24.9915H350.361V19.6486H352.7V24.9915H360.475V26.999H352.7V42.7274C352.7 44.3239 353.079 45.5253 353.853 46.3473C354.612 47.1693 355.75 47.5803 357.251 47.5803C358.752 47.5803 360.048 47.1218 361.044 46.2208L362.023 47.8648V47.8806Z" fill="#B3B3B3"/>
        <path d="M384.952 37.7639H363.935C363.998 39.6607 364.488 41.368 365.389 42.838C366.29 44.3239 367.522 45.4621 369.055 46.284C370.604 47.0902 372.342 47.5012 374.27 47.5012C375.866 47.5012 377.336 47.2167 378.695 46.6318C380.054 46.0469 381.176 45.2091 382.092 44.0868L383.451 45.6359C382.392 46.9163 381.049 47.8964 379.453 48.5919C377.841 49.2716 376.103 49.6194 374.238 49.6194C371.805 49.6194 369.64 49.0978 367.728 48.0229C365.831 46.9638 364.33 45.4937 363.255 43.6284C362.181 41.7631 361.644 39.6133 361.644 37.2106C361.644 34.8079 362.149 32.7055 363.161 30.8244C364.172 28.9433 365.563 27.4574 367.348 26.3983C369.134 25.3393 371.125 24.8018 373.338 24.8018C375.55 24.8018 377.541 25.3234 379.311 26.3667C381.081 27.41 382.456 28.8643 383.467 30.7454C384.478 32.6107 384.984 34.7447 384.984 37.1158L384.937 37.7797L384.952 37.7639ZM368.707 28.0107C367.333 28.7695 366.242 29.8602 365.405 31.2354C364.567 32.6107 364.109 34.1914 363.982 35.946H382.693C382.598 34.1914 382.124 32.6423 381.286 31.267C380.449 29.8918 379.343 28.8169 377.968 28.0423C376.593 27.2678 375.044 26.8726 373.338 26.8726C371.631 26.8726 370.082 27.2519 368.707 28.0265V28.0107Z" fill="#B3B3B3"/>
        <path d="M35.2403 54.251C45.8007 54.251 54.3616 45.6875 54.3616 35.124C54.3616 24.5605 45.8007 15.9971 35.2403 15.9971C24.68 15.9971 16.1191 24.5605 16.1191 35.124C16.1191 45.6875 24.68 54.251 35.2403 54.251Z" fill="#004C78"/>
        <path d="M51.501 51.5163L56.305 56.3217C61.4566 51.1685 64.475 44.4662 64.9332 37.258H58.2329C57.7272 42.8064 55.2462 47.8016 51.501 51.5163Z" fill="#5FE3C4"/>
        <path d="M45.0848 63.3718C43.631 63.8777 42.1139 64.2412 40.5811 64.5258C42.1139 64.2571 43.631 63.8777 45.0848 63.3718Z" fill="#4AAF98"/>
        <path d="M37.2471 58.1238V64.9526C38.3691 64.8893 39.4752 64.7313 40.5814 64.5258C42.1143 64.2571 43.6313 63.8777 45.0852 63.3718C45.3538 63.277 45.6225 63.1505 45.8911 63.0557C46.4442 62.8502 47.0131 62.6447 47.5504 62.3918C47.5978 62.376 47.6452 62.3444 47.6926 62.3127C48.4512 61.965 49.1939 61.6014 49.905 61.1904C50.1894 61.0323 50.4581 60.8427 50.7425 60.6688C51.2166 60.3684 51.7065 60.0997 52.1648 59.7678C52.5756 59.4832 52.9707 59.1513 53.3816 58.8509C53.6818 58.6138 54.0137 58.3925 54.2981 58.1396C54.6142 57.8867 54.8986 57.5863 55.1989 57.3176C55.5623 56.9856 55.9416 56.6695 56.3051 56.3059L51.5011 51.5005C47.7716 55.1994 42.778 57.6338 37.2471 58.108V58.1238Z" fill="#4AAF98"/>
        <path d="M35.2401 12.0294C35.9196 12.0294 36.5833 12.0768 37.2313 12.1243V1.89689C37.2313 0.837792 36.3779 0 35.3349 0C25.9008 0 17.0197 3.68312 10.3509 10.3538C8.6864 12.0189 8.6864 12.0189 10.3509 10.3538L18.8686 18.874C23.0563 14.6535 28.8559 12.0294 35.2401 12.0294Z" fill="#3D70A4"/>
        <path d="M44.2318 7.0501C43.6471 6.87622 43.0308 6.97106 42.5409 7.33463C42.3039 7.50851 42.0985 7.74562 41.972 8.01435C41.8456 8.28307 41.7666 8.56761 41.7666 8.86795V12.9937C51.3272 15.8074 58.3436 24.6595 58.3436 35.1398C58.3436 35.8512 58.2962 36.5625 58.233 37.2738H64.9333C64.9649 36.6415 64.9965 36.0092 64.9965 35.3769C64.9965 21.9407 56.1154 10.7964 44.2318 7.08171V7.0501Z" fill="#2D93D0"/>
        <path d="M18.8684 18.874L10.3507 10.3538C7.85392 12.8514 5.76797 15.6651 4.14029 18.7001C3.603 19.7118 3.11312 20.7551 2.67065 21.8142C1.8015 23.9324 1.12199 26.1296 0.679514 28.4059C0.23704 30.6663 0 32.99 0 35.3611C0 36.4202 0.853343 37.258 1.89632 37.258H12.2629C12.1996 36.5625 12.1522 35.8512 12.1522 35.124C12.1522 28.7853 14.7123 23.0472 18.8526 18.874H18.8684Z" fill="#B3B3B3"/>
        <path d="M35.2403 58.2186C24.8264 58.2186 15.9927 51.2792 13.1324 41.7631H8.84991C8.2494 41.7631 7.68051 42.0477 7.31705 42.5377C6.95359 43.0277 6.85877 43.66 7.0326 44.2291C10.762 56.1004 21.8713 64.9842 35.3193 64.9842C35.9515 64.9842 36.5994 64.9684 37.2157 64.921V58.0922C36.552 58.1554 35.8882 58.187 35.2245 58.187L35.2403 58.2186Z" fill="#617185"/>
        </g>
        <defs>
        <clipPath id="clip0_27_16470">
        <rect width="385" height="65" fill="white"/>
        </clipPath>
        </defs>
        </svg>
)


class ContentClass extends React.Component {
    constructor(props) {
        super();
        this.location = props.location.location;
        const params = new URLSearchParams(this.location.search)

        this.state = {
            watch_video: this.location.search && this.location.search.includes('watch_video'),
            tracking_source: this.location.search && this.location.search.includes('utm_source') ? params.get('utm_source') : 'Other',
            submitted: this.location.search && this.location.search.includes('submit')
        };
    }

    render() {
        return (
            <WrapperDiv>
                {logo()}
                <Flex>
                    <Content>
                        <p className="margin-bottom-10"><strong>Ladd PSA Lite is our implementation methodology for mid size Commercial PSA Customers</strong></p>
                        <p>We implement a core set of required PSA functionality, then tailor “A-La-Carte” components based on the Customers current needs.</p>

                        <h2 className="margin-top-40">Why PSA Lite?</h2>

                        <p className="margin-bottom-10"><strong>"Traditional” full service PSA implementations work well for large Enterprise Customers</strong></p>
                        <ul className="margin-bottom-40">
                            <li>Need the majority of PSA features implemented</li>
                            <li>Many stakeholders, multiple locations, complex implementations</li>
                            <li>Large budgets, 12-18 month implementation cycles</li>
                        </ul>

                        <p className="margin-bottom-10"><strong>“SmartStart” implementations work well for small customers</strong></p>
                        <ul className="margin-bottom-40">
                            <li>Need to get up and running quickly with a predefined set of features</li>
                            <li>Less complex, small teams</li>
                            <li>Cost is a barrier, 2-3 month implementation cycles</li>
                         </ul>

                        <p className="margin-bottom-10"><strong>…But what about mid market Customers?</strong></p>
                        <ul className="margin-bottom-40">
                            <li>In our experience, one size does not fit all</li>
                            <li>Concerned about budget, but expect to see the demonstrated PSA features they need fully implemented</li>
                            <li>Often dealing with integrations to other systems</li>
                            <li>Time to value is important</li>
                        </ul>
                    </Content>

                   <Content className="benefits">
                    <Benefits>
                        <p className="intro margin-bottom-10">Product Advatages</p>
                        <ul>
                            <li>Faster time to value</li>
                            <li>Explicit, upfront feature vs. implementation cost trade off</li>
                            <li>Reduced overall costs - you don't pay to implement features you don't need</li>
                            <li>The ability to add more functionality later, if and when it becomes required</li>
                        </ul>
                        {/* <p className="margin-bottom-0">Download Solutions Overview</p>
                        <a href={PDF} target="_blank"><strong>Solution Overview</strong> (.pdf)</a> */}                          
                    </Benefits>
                   </Content>
                   <img className="info" src={INFO} alt="Infograph"/>
                </Flex>
        </WrapperDiv>
        )
    }
}

export default ContentClass
