import React from "react"
import { Location } from '@reach/router'
import Hero from "../../components/hero-psa"
import Content from "../../components/content-psa"

import "../../assets/index.css"
import Layout from "../../layouts/layout"

export default () => (
    <Layout title="PSA Lite | Ladd Partners">
        <Hero/>

        <Location>
            {({ location }) => {
                return  <><Content location={{ location }} /></>
            }}
        </Location>
    </Layout>
)
